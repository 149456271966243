import Global from './tasks/responsive';
import ModalVideo from './tasks/modal-popup-video';


window.tasks = {};

let BootstrapTasks = [
    Global,
    ModalVideo
];

export { BootstrapTasks };
