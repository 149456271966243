import { BootstrapTasks } from './bootstrap';
// import Test from './vimeo.js';
// require('./vimeo.js');

document.addEventListener("DOMContentLoaded", function(event) {

    //TODO dynamically find the y position of the container to set height on the background parallax image

    // var _wedge = document.getElementsByClassName('wedge-nav')[0];
    // var _o = _wedge.outerHeight();
    // console.log("outHeight: " , _o );
    console.log("DOMContentLoaded: " );
    BootstrapTasks.forEach((task, index) => {
        window.tasks[index] = new task();
        window.tasks[index].init();
    });
});


