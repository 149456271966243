
export default class {
    constructor() {
        this.isInit = false;

        this.container = null;
        this.hitArea = null;
        this.loadingSpinner = null;
        this.playerContainer = null;
        this.closeButton = null;
        this.iframe = null;
        
        this.vimeoInit = false;
        this.videoId = null;
        this.player = null;

        // this.sdkContainer = null;
    }

    init(){
        this.setup();
    }

    setup(){
        this.closeButton = document.querySelector('.player-popup .close-button');
        this.closeButton.addEventListener('click', (e) => this.onCloseHandler(e));

        this.iframe = document.querySelector('#popup-video-frame');

        this.container = document.getElementsByClassName('player-popup')[0];
        this.hitArea = document.getElementsByClassName('background-hit-area')[0];
        this.hitArea.addEventListener('click', (e) => this.onCloseHandler(e));

        this.loadingSpinner = document.getElementsByClassName('loading-spinner')[0];
        this.playerContainer = document.getElementsByClassName('player-container')[0];

        var playButtons = document.querySelectorAll('.play-video-btn');
        for (const button of playButtons) {
            button.addEventListener('click', (e) => this.onClickHandler(e));
        }
    }

    // setupPlaceholder(){
    //     this.sdkContainer = document.createElement('div');
    //     this.sdkContainer.setAttribute("id", "popup-video-frame");
    //     this.closeButton.parentNode.insertBefore(this.sdkContainer, this.closeButton);
    // }

    // setupPlayer(){
    //     var _options = {
    //         id: this.videoId,
    //         width:100%,
    //         height:100%
    //     }

    //     this.player = new Vimeo.Player("popup-video-frame", _options);
    // }

    // setupIframe(s){
    //     // example:<iframe id="popup-video-frame" src="" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe>
        
    //     /* Dynamically add iframe each time - used in conjunction with destory() */
    //     // this.iframe = document.createElement('iframe');
    //     // this.iframe.width = "100%";
    //     // this.iframe.height = "100%";
    //     // this.iframe.setAttribute("frameborder", "0");
    //     // this.iframe.setAttribute("allow", "autoplay; fullscreen");
    //     // this.iframe.setAttribute('webkitallowfullscreen', '');
    //     // this.iframe.setAttribute('mozallowfullscreen', '');
    //     // this.iframe.setAttribute('allowFullScreen', '');

    //     // this.closeButton.parentNode.insertBefore(this.iframe, this.closeButton);

    //     if(this.iframe){
    //         this.iframe.src = s;

    //         this.player = new Vimeo.Player(this.iframe);

    //         this.enableListeners(true);

    //     } else {
    //         console.log("Error: No iframe found");
    //     }
    // }

    // Methods

    showContainer(b){
        if(b){
            this.container.style.display = 'block';
        } else {
            this.container.style.display = 'none';
        }
    }

    showSpinner(b){
        if(b){
            this.loadingSpinner.style.display = 'block';
        } else {
            this.loadingSpinner.style.display = 'none';
        }
    }

    showPlayer(b){
        console.log('showPlayer ' + b);
        if(b){
            this.playerContainer.style.display = 'block';
        } else {
            this.playerContainer.style.display = 'none';
        }
    }





    stopPlayer(callback){
        this.player.getEnded().then((endeed) => {
            var hasEnded = endeed;
            console.log(" >>video ended ", endeed);
            if(!hasEnded){
                console.log("  >>check if paused");
                this.player.getPaused().then( (paused) => {
                    console.log('   >>paused: ' + paused);
                    if(paused){
                        if(callback) callback();
                    } else {
                        this.player.pause().then( () => {
                            // The video is paused
                            console.log("    >>we paused the video");
                            console.log("callback ", callback)
                            if(callback) callback();

                            }).catch(function(error) {
                                switch (error.name) {
                                case 'PasswordError':
                                    // The video is password-protected
                                    break;
                            
                                case 'PrivacyError':
                                    // The video is private
                                    break;
                            
                                default:
                                    // Some other error occurred
                                    break;
                                }
                        });
                    }
                }
            )} else {
                if(callback) callback();
            }
        });
    }

    closePopup(){
        console.log("!! closePopup !!");
        this.enableEscListener(false);
        
        this.showPlayer(false);
        this.showContainer(false);
    }

    setVolume(v){
        this.player.setVolume(v).then(function(volume) {
            console.log("volume set: "+ v);
            // The volume is set
          }).catch(function(error) {
            console.log("volume set ERROR: " , error);
            switch (error.name) {
              case 'RangeError':
                  // The volume is less than 0 or greater than 1
                  break;
          
              default:
                  // Some other error occurred
                  break;
            }
          });
    }

    setTime(time){
        this.player.setCurrentTime(time).then( (seconds) => {
            console.log("setTime ", seconds);
            this.showPlayer(true);
            // `seconds` indicates the actual time that the player seeks to
          }).catch(function(error) {
            console.log("setTime ERROR: ", error.name);
            switch (error.name) {
              case 'RangeError':
                  // The time is less than 0 or greater than the video's duration
                  break;
              default:
                  // Some other error occurred
                  break;
            }
        });
    }
    
    loadVideo(id){
        console.log("loadVideo: "+ id);
        this.player.loadVideo(id);
    }

   
    enableListeners(b){
        if(b){
            this.player.on('loaded', () => this.onLoaded());
            this.player.on('bufferend', () => this.onBufferend());
            this.player.on('bufferstart', () => this.onBufferstart());
            this.player.on('ended', () => this.onEnded());
            this.player.on('progress', (e) => this.onProgress(e));
            this.player.on('play', (e) => this.onPlay(e));
        } else {
            this.player.off('loaded', () => this.onLoaded());
            this.player.off('bufferend', () => this.onBufferend());
            this.player.off('bufferstart', () => this.onBufferstart());
            this.player.off('ended', () => this.onEnded());
            this.player.off('progress', (e) => this.onProgress(e));
            this.player.off('play', (e) => this.onPlay(e));
        }
    }

    enableEscListener(b){
        if(b){
            document.onkeydown = function(evt) {
                evt = evt || window.event;
                var isEscape = false;
                if ("key" in evt) {
                    isEscape = (evt.key === "Escape" || evt.key === "Esc");
                } else {
                    isEscape = (evt.keyCode === 27);
                }
                if (isEscape) {
                    this.closePopup();
                }
            };
        } else {
            document.onkeydown = null;
        }
    }
    

   
    // Listeners

    onLoaded(){
        console.log('onLoaded ');
        this.showPlayer(true);
        this.showSpinner(false);
        // this.player.play();
    }

    onPlay(){
        console.log('onPlay ');
        // this.setVolume(1);
        // this.player.pause();
        // player.play()
        // player.pause()
        // setTimeout(() => { this.player.play() }, 750);
    }

    onBufferstart(e){
        console.log("onBufferstart");
    }

    onBufferend(e){
        console.log("onBufferend");
    }

    onProgress(e){
        console.log("onProgress " , e);
    }

    onEnded(){
        console.log('onEnded');
        // this.stopPlayer();

        // this.showPlayer(false);
        // this.showContainer(false);

        // this.destoryPlayer();
    }


    



    //Handlers

    onClickHandler(e){
        console.log("onClickHandler vimeoInit: " + this.vimeoInit);
        this.videoId = e.currentTarget.getAttribute('data-video');
        
        
        if(!this.vimeoInit){
            this.vimeoInit = true;

            this.options = 'loop=0&background=0&byline=0&title=0&muted=0&controls=1';
            var _src = "https://player.vimeo.com/video/" + this.videoId +"?" + this.options;

            this.iframe.src = _src

            this.player = new Vimeo.Player(this.iframe);
            this.enableListeners(true);

        } else {
            this.player.loadVideo(this.videoId);
            this.showPlayer(true);
        }
       
        this.enableEscListener(true);
        this.showContainer(true);
        this.showSpinner(true);
    }

    onCloseHandler(e){
        console.log("onCloseHandler ");
        this.stopPlayer(() => { this.closePopup()} );
        // this.closePopup();
    }
}