import debounce from 'debounce';

export default class {
    constructor() {
    }

    init(){
        this.setup();
    }

    setup(){
        this.resize();
        window.onresize = debounce(this.resize, 200);
        // window.addEventListener('resize', reportWindowSize);
    }


    resize() {
        //For bottom aligned footer the #main-content bottom padding needs to match the height of the footer

        var _app = document.getElementById('app');
        var _footer = document.querySelector('footer');
        var _height = _footer.offsetHeight;
        _app.style.paddingBottom = _height +'px';
    }

}
